/**
 * Represents the statusCode field of the INotification model.
 * ! For future developers: add the new field to the end, or else it'll mix up with the data that comes from the server!
 */
export enum ENotificationIcons {
    // Default + Positive
    expectedCargoReadyDate = 1,
    actualCargoReadyDate = 2,
    shipmentBooked = 3,
    expectedPickupOrigin = 4,
    actualPickupOrigin = 5,
    expectedPickupDest = 6,
    actualPickupDest = 7,
    expectedDeparture = 8,
    actualDeparture = 9,
    telexReleasePositive = 9.5,
    customsCleared = 9.75,
    expectedArrivalToPort = 10,
    actualArrivalToPort = 11,
    shipmentAvailable = 12,
    exptPickupToDelivery = 13,
    actualPickupToDelivery = 14,
    shipmentArrived = 15,
    podScanned = 16,
    // negatives
    exptCargoReadyDateChanged = 1.5,
    expPickupFromOriginChanged = 4.5,
    expDepartureChanged = 8.5,
    expArrivalToPortChanged = 10.5,
    exptPickupToDeliveryChanged = 13.5,
}
