import { Dialog } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { CloseIcon, LongRightArrow } from "../../../assets/icons";
import { getShipmentCurrentStage } from "../../../helpers/services/ShipmentService";
import { ETransportModes, IShipment } from "../../../models";
import { useUpdateShipmentMutation } from "../../../store/api-slices/shipmentsSlice.api";
import { OutlinedButton } from "../../buttons";
import {
    getCardColor,
    getShipmentCardIcon,
    roundNumber,
} from "../../cards/shipment-cards/shared/services/shipment-cards.service";
import ContactUs from "../../contactus/ContactUs";
import Block from "../block/Block";
import { STRINGS } from "./shared/resources";
import { ShipmentDetailsTabs } from "./sub-components/ShipmentDetailsTabs/ShipmentDetailsTabs";
import useStyles from "./useStyles";
import { ScreenWidthType } from "../../../assets/data/ui";
import { useScreenWidthType } from "../../../helpers/custom-hooks/useScreenWidthType";
import StageColorContainer from "../../colorContainer/StageColorContainer";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";
import { ReactComponent as Checkmark } from "../../../assets/icons/check-mark.svg";
import { ReactComponent as DecorativeLine1 } from "../../../assets/icons/decorative-line-1.svg";
import { ReactComponent as CustomExam } from "../../../assets/icons/custom-exam.svg";
import { getTranshipmentsDetails } from "./sub-components/AdditionalShipmentDetails/AdditionalDetails.service";
import { getGrossWeightInKGFormattedValue } from "./utils/shipmentDetailsUtil";
import UnicargoTooltip from "../../UnicargoTooltip/UnicargoTooltip";
import { getFormattedDate } from "../../../helpers/services";
import { SPECIAL_SERVICES } from "../../../models/stepper/utils/SpecialServices";

interface IShipmentDetailsBlock {
    shipment: IShipment;
    handleDataChange?: (shipmentNumber: string) => void;
    handleCloseClicked: () => void;
    originPage?: string;
}

const ShipmentDetailsBlock = ({
    shipment,
    handleDataChange,
    handleCloseClicked,
    originPage = "Shipments Page",
}: IShipmentDetailsBlock) => {
    const classes = useStyles();
    const isMobile = useScreenWidthType() === ScreenWidthType.MOBILE;
    const [triggerPut, putResult] = useUpdateShipmentMutation();
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
    const { shipmentDetails, preDelivery, postDelivery, steps, packageDetails } = shipment;
    const {
        transportMode,
        shipmentNumber,
        quoteNumber,
        mainCarriageATA,
        customsClearence,
        shipmentLoad,
        amazonReferenceID,
        amazonShipmentID,
        c_customerReferenceID,
        subcategoryValue,
        moveTypeEnglishName,
        specialServicesEnglishName,
        packageQuantityType,
        telexReleasDate,
    } = shipmentDetails;
    const { numberOfContainers, numberOfPackages, totalVolumInCBM, grossWeightInKG } = packageDetails;
    const { purchaseOrderNumber } = preDelivery;
    const { PODRecievedDate } = postDelivery;
    const { cleared, clearenceDate, customsExamCompleted, customsExamNotification } = customsClearence!;
    const stage = getShipmentCurrentStage(
        steps,
        ETransportModes[transportMode],
        PODRecievedDate,
        mainCarriageATA,
        shipmentDetails,
    );
    const shipmentTypeIcon = getShipmentCardIcon(ETransportModes[transportMode]);
    const transShipmentsCount = getTranshipmentsDetails(steps);

    useEffect(() => {
        if (putResult.isSuccess && handleDataChange) {
            handleDataChange(putResult.data.data.update.shipmentNumber);
        }
    }, [putResult]);

    const stageColor = useMemo(() => {
        return getCardColor(stage);
    }, [stage]);

    const letsTalkAboutItBtnPress = () => {
        setIsContactUsOpen(true);
        tryTrackEvent(
            `[${originPage}]: 'Lets talk about it' button clicked ${
                originPage !== "Shipments page" ? "(in shipment drawer)" : ""
            }`,
        );
    };

    const renderHead = useMemo(() => {
        return (
            <div className={classes.headContainer}>
                {isMobile && (
                    <div className={classes.mobileCloseButtonContainer}>
                        <button onClick={() => handleCloseClicked()} className={classes.closeButton}>
                            {<CloseIcon />}
                        </button>
                    </div>
                )}
                <div className={classes.headContent}>
                    <div className={classes.primaryDetailsContainer}>
                        <div className={classes.primaryDetails}>
                            {/* This is the Headline */}
                            <div className={classes.shipmentNumberContainer}>
                                <div className={classes.shipmentNumberWrapper}>
                                    <div className={classes.shipmentNumberWithIconWrapper}>
                                        {shipmentTypeIcon}
                                        <p className={classes.shipmentNumber}>{shipmentNumber}</p>
                                    </div>
                                    <DecorativeLine1 />
                                </div>
                                <div className={classes.stageChip}>
                                    <StageColorContainer color={stageColor} />
                                    {stage}
                                </div>
                            </div>
                            {/* This is the reference */}
                            <div className={classes.shipmentReferencesContainer}>
                                {c_customerReferenceID && (
                                    <div className={classes.referenceItemWrapper}>
                                        <span className={classes.referenceTitleItem}>Customer ref.ID </span>
                                        <span>&nbsp;</span>
                                        <span className={classes.referenceContentItem}>{c_customerReferenceID}</span>
                                    </div>
                                )}
                                {purchaseOrderNumber && (
                                    <div className={classes.referenceItemWrapper}>
                                        {c_customerReferenceID && <span className={classes.divider}></span>}
                                        <span className={classes.referenceTitleItem}>PO </span>
                                        <span>&nbsp;</span>
                                        <span className={classes.referenceContentItem}>{purchaseOrderNumber}</span>
                                    </div>
                                )}

                                {amazonShipmentID && (
                                    <div className={classes.referenceItemWrapper}>
                                        {(c_customerReferenceID || purchaseOrderNumber) && (
                                            <span className={classes.divider}></span>
                                        )}
                                        <span className={classes.referenceTitleItem}>Amazon Shipment ID</span>
                                        <span>&nbsp;</span>
                                        <span className={classes.referenceContentItem}>{amazonShipmentID}</span>
                                    </div>
                                )}

                                {amazonReferenceID && (
                                    <div className={classes.referenceItemWrapper}>
                                        {(c_customerReferenceID || purchaseOrderNumber || amazonShipmentID) && (
                                            <span className={classes.divider}></span>
                                        )}
                                        <span className={classes.referenceTitleItem}>Amazon Reference ID </span>
                                        <span>&nbsp;</span>
                                        <span className={classes.referenceContentItem}>{amazonReferenceID}</span>
                                    </div>
                                )}

                                {quoteNumber && (
                                    <div className={classes.referenceItemWrapper}>
                                        {purchaseOrderNumber ||
                                        c_customerReferenceID ||
                                        amazonShipmentID ||
                                        amazonReferenceID ? (
                                            <span className={classes.divider}></span>
                                        ) : null}
                                        <span className={classes.referenceTitleItem}>Quote no. </span>
                                        <span>&nbsp;</span>
                                        <span className={classes.referenceContentItem}>{quoteNumber}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!isMobile && (
                            <div>
                                <OutlinedButton
                                    onClick={() => letsTalkAboutItBtnPress()}
                                    className={`${classes.contactUsButton}`}
                                >
                                    {STRINGS.buttonText}
                                </OutlinedButton>
                                <Dialog open={isContactUsOpen} onClose={() => setIsContactUsOpen(false)}>
                                    <ContactUs shipment={shipment} setIsOpen={setIsContactUsOpen} />
                                </Dialog>
                            </div>
                        )}
                    </div>
                    <div className={classes.shippingAndPackageDetails}>
                        <div className={classes.greyBox}>
                            <span className={classes.detailsHeader}>Shipping Details: </span>
                            <div className={classes.shippingDetailsItemsWrapper}>
                                <div className={classes.detailsTextContainer}>
                                    {shipmentLoad != null && (
                                        <span className={classes.detailsText}>{shipmentLoad}</span>
                                    )}
                                </div>
                                {/* Sub service */}
                                <div className={classes.detailsTextContainer}>
                                    {specialServicesEnglishName && (
                                        <>
                                            {shipmentLoad != null ? <span className={classes.divider}></span> : null}
                                            <span className={classes.detailsText}>{specialServicesEnglishName}</span>
                                        </>
                                    )}
                                </div>
                                {/* Move Type  */}
                                <div className={classes.detailsTextContainer}>
                                    {moveTypeEnglishName && (
                                        <>
                                            {shipmentLoad != null || subcategoryValue != null ? (
                                                <span className={classes.divider}></span>
                                            ) : null}
                                            <span className={classes.detailsText}>{moveTypeEnglishName}</span>
                                        </>
                                    )}
                                </div>
                                {/* Direct / Transshipment */}
                                <div className={classes.detailsTextContainer}>
                                    {shipmentLoad != null &&
                                        !specialServicesEnglishName?.includes(SPECIAL_SERVICES.EXPRESS) &&
                                        !specialServicesEnglishName?.includes(SPECIAL_SERVICES.TRUCKING) && (
                                            <>
                                                {transShipmentsCount != null ? (
                                                    <span className={classes.divider}></span>
                                                ) : null}
                                                {transShipmentsCount <= 0 ? (
                                                    <span className={classes.detailsText}>Direct</span>
                                                ) : specialServicesEnglishName === SPECIAL_SERVICES.AIR_FREIGHT ? (
                                                    <span className={classes.detailsText}>Non Direct</span>
                                                ) : (
                                                    <span className={classes.detailsText}>Transshipment</span>
                                                )}
                                            </>
                                        )}
                                </div>
                                <div className={classes.detailsTextContainer}>
                                    {shipment.shipmentDetails.fromLocation?.city &&
                                        shipment.shipmentDetails.destLocation?.city && (
                                            <div className={classes.shipmentFromToDestWrapper}>
                                                {transShipmentsCount !== null ? (
                                                    <span className={classes.divider}></span>
                                                ) : null}
                                                <span className={classes.detailsText}>
                                                    {shipment.shipmentDetails.fromLocation.city},{" "}
                                                    {shipment.shipmentDetails.fromLocation.country}
                                                    <LongRightArrow className={classes.rightArrowIcon} />
                                                    {shipment.shipmentDetails.destLocation.city},
                                                    {shipment.shipmentDetails.destLocation.country}
                                                </span>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                        {/* This is the package details */}
                        <div className={classes.greyBox}>
                            <span className={classes.detailsHeader}>Package Details: </span>

                            {!packageQuantityType &&
                            !numberOfContainers &&
                            !numberOfPackages &&
                            !grossWeightInKG &&
                            !totalVolumInCBM ? (
                                <span>...</span>
                            ) : (
                                <>
                                    <div className={classes.packagesDetailsRow}>
                                        <div className={classes.detailsTextContainer}>
                                            {packageQuantityType && shipmentLoad === "FCL" && (
                                                <span className={classes.detailsText}>{packageQuantityType}</span>
                                            )}
                                        </div>
                                        <div className={classes.detailsTextContainer}>
                                            <>
                                                {numberOfContainers && shipmentLoad === "FTL" && (
                                                    <span
                                                        className={classes.detailsText}
                                                    >{`${numberOfContainers} Full Track`}</span>
                                                )}
                                            </>
                                        </div>
                                        <div className={classes.detailsTextContainer}>
                                            {!!numberOfPackages && (
                                                <>
                                                    {(packageQuantityType && shipmentLoad === "FCL") ||
                                                    (numberOfContainers && shipmentLoad === "FTL") ? (
                                                        <span className={classes.divider}></span>
                                                    ) : null}
                                                    {!!numberOfPackages && (
                                                        <span
                                                            className={classes.detailsText}
                                                        >{`${numberOfPackages} Packages`}</span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className={classes.detailsTextContainer}>
                                            {!!grossWeightInKG && (
                                                <>
                                                    {(packageQuantityType &&
                                                        (shipmentLoad === "FTL" || shipmentLoad === "FCL")) ||
                                                    numberOfPackages ? (
                                                        <span className={classes.divider}></span>
                                                    ) : null}
                                                    <span className={classes.detailsText}>
                                                        {`${getGrossWeightInKGFormattedValue(grossWeightInKG)} KG`}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <div className={classes.detailsTextContainer}>
                                            {!!totalVolumInCBM && shipmentLoad !== "FCL" && (
                                                <>
                                                    {numberOfContainers || numberOfPackages || grossWeightInKG ? (
                                                        <span className={classes.divider}></span>
                                                    ) : null}
                                                    <span className={classes.detailsText}>
                                                        <span className={classes.detailsText}>{`${roundNumber(
                                                            totalVolumInCBM,
                                                            2,
                                                        )} CBM`}</span>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {/* This is the cargo status */}
                    <div className={classes.cargoStatusContainer}>
                        {customsExamNotification && !customsExamCompleted && !cleared && (
                            <UnicargoTooltip
                                popperChildComponent={getFormattedDate(customsExamNotification as string)}
                                child={
                                    <div>
                                        <CustomExam />
                                        Customs Exam
                                    </div>
                                }
                                childClassName={classes.CargoStatusItem}
                                popperClassName="popperClassName"
                            />
                        )}

                        {customsExamNotification && (customsExamCompleted || cleared) && (
                            <UnicargoTooltip
                                popperChildComponent={
                                    customsExamCompleted ? getFormattedDate(customsExamCompleted as string) : undefined
                                }
                                child={
                                    <div className={classes.CargoStatusItem}>
                                        <Checkmark />
                                        Customs Exam Completed
                                    </div>
                                }
                                childClassName={classes.CargoStatusItem}
                                popperClassName="popperClassName"
                            />
                        )}

                        {cleared && (
                            <UnicargoTooltip
                                popperChildComponent={getFormattedDate(clearenceDate as string)}
                                child={
                                    <div>
                                        {customsExamNotification ? <span className={classes.divider}></span> : null}
                                        <Checkmark />
                                        Customs Cleared
                                    </div>
                                }
                                childClassName={classes.CargoStatusItem}
                                popperClassName="popperClassName"
                            />
                        )}

                        {PODRecievedDate && (
                            <UnicargoTooltip
                                popperChildComponent={getFormattedDate(PODRecievedDate)}
                                child={
                                    <div className={classes.CargoStatusItem}>
                                        {cleared || customsExamNotification ? (
                                            <span className={classes.divider}></span>
                                        ) : null}
                                        <Checkmark />
                                        POD Received
                                    </div>
                                }
                                childClassName={classes.CargoStatusItem}
                                popperClassName="popperClassName"
                            />
                        )}

                        {telexReleasDate && shipmentLoad === "FCL" && (
                            <UnicargoTooltip
                                popperChildComponent={getFormattedDate(telexReleasDate)}
                                child={
                                    <div className={classes.CargoStatusItem}>
                                        {cleared || PODRecievedDate ? <span className={classes.divider}></span> : null}
                                        <Checkmark />
                                        Telex Received
                                    </div>
                                }
                                childClassName={classes.CargoStatusItem}
                                popperClassName="popperClassName"
                            />
                        )}
                    </div>
                </div>
                {/* <PillContainer
                    leftSide={<AdditionalShipmentDetails shipment={shipment} />}
                    className={classes.headerPillContainer}
                /> */}
            </div>
        );
    }, [shipment, handleCloseClicked, isContactUsOpen]);

    return (
        <Block className={classes.root}>
            {renderHead}
            <ShipmentDetailsTabs shipment={shipment} />
        </Block>
    );
};

export default ShipmentDetailsBlock;
