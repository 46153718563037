import { Button, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { UiSchemeV2 } from "../../../assets/data/ui";
import { ExportExcelIcon } from "../../../assets/icons";
import { IShipmentQueryParams } from "../../../helpers/services/ShipmentService";
import { IShipment } from "../../../models";
import { useLazyGetShipmentsDataForDownloadByCustomerCodeQuery } from "../../../store/api-slices/shipmentsSlice.api";
import UnicargoTooltip from "../../UnicargoTooltip/UnicargoTooltip";
import { tryTrackEvent } from "../../../helpers/services/MixPanelService";

const radioButtonOptions = {
    RAW_DATA: "rawData",
    EXTENDED: "extended",
    BASIC: "basic",
};

interface IProps {
    customerCode: string;
    queryParams: IShipmentQueryParams;
    shipments: IShipment[] | undefined;
}

const ShipmentExportButton = ({ customerCode, queryParams, shipments }: IProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(radioButtonOptions.BASIC);
    const buttonRef = useRef(null);
    const [shouldShowError, setShouldShowError] = useState(false);
    const [shouldShowEmailSent, setShouldShowEmailSent] = useState(false);
    const [getShipmentsData, { isError, isFetching }] = useLazyGetShipmentsDataForDownloadByCustomerCodeQuery();

    useEffect(() => {
        if (isError) {
            handleError();
        }
    }, [isError]);

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleError = () => {
        tryTrackEvent("[Shipments Page]: Error Exporting file", {
            reportType: selectedOption,
            shipmentsCount: shipments?.length?.toString() ?? "0"
        });

        setShouldShowError(true);
        setTimeout(() => {
            setShouldShowError(false);
        }, 5000);
    };

    const handleEmailSent = () => {
        setShouldShowEmailSent(true);
        setTimeout(() => {
            setShouldShowEmailSent(false);
        }, 5000);
    };

    const handleButtonPress = async () => {
        const exportType = selectedOption === radioButtonOptions.BASIC ? "Basic" :
            selectedOption === radioButtonOptions.EXTENDED ? "Extended" : "Raw Data";

        const res = await getShipmentsData({
            customerCode,
            params: {
                ...queryParams,
                reportType: selectedOption,
            },
        });

        tryTrackEvent(`[Shipments Page]: Export ${exportType} by ${res.data?.isSentViaEmail ? "Email" : "Download"}`);

        if (res.data?.isSentViaEmail) {
            handleEmailSent();
        }
        handleClose();
    };

    const toolTipElement = (
        <div style={{ fontSize: "10px" }}>
            {shouldShowEmailSent
                ? "Your report is on its way! You’ll receive it in your email shortly"
                : "Error downloading file, Please try again later!"}
        </div>
    );

    const exportBtn = (
        <div>
            <Button
                style={{
                    background: "white",
                    border: `1px solid ${UiSchemeV2.colors.primaryDark}`,
                    display: "flex",
                    // width: "101px",
                    height: "32px",
                    padding: "5px 10px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: "0",
                    boxShadow: "none",
                    fontSize: "13px",
                }}
                ref={buttonRef}
                variant="contained"
                startIcon={<ExportExcelIcon />}
                onClick={handleOpen}
            >
                Export
            </Button>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        borderRadius: "16px",
                    },
                }}
                style={{
                    marginTop: "10px",
                }}
            >
                <div
                    className="ExportBtnPopoverContent"
                    style={{
                        width: "200px",
                        padding: "10px 20px",
                        flexShrink: "0",
                        background: "white",
                        borderRadius: "16px",
                        border: `1px solid ${UiSchemeV2.colors.greys.grey100}`,
                        boxShadow: "0px 4px 10px 0px rgba(51, 51, 51, 0.12)",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                    }}
                >
                    <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                        <FormControlLabel
                            value={radioButtonOptions.BASIC}
                            control={<Radio />}
                            label="Basic"
                            style={{ marginRight: "0" }}
                            componentsProps={{ typography: { fontSize: "14px" } }}
                        />
                        <FormControlLabel
                            value={radioButtonOptions.EXTENDED}
                            control={<Radio />}
                            label="Extended"
                            style={{ marginRight: "0" }}
                            componentsProps={{ typography: { fontSize: "14px" } }}
                        />
                        <FormControlLabel
                            value={radioButtonOptions.RAW_DATA}
                            control={<Radio />}
                            label="Raw Data"
                            style={{ marginRight: "0" }}
                            componentsProps={{ typography: { fontSize: "14px" } }}
                        />
                    </RadioGroup>
                    <Button
                        style={{ margin: 0, width: "92px", height: "32px" }}
                        variant="contained"
                        onClick={handleButtonPress}
                        disabled={isFetching}
                    >
                        Download
                    </Button>
                </div>
            </Popover>
        </div>
    );

    return (
        <div>
            <UnicargoTooltip
                isOpen={shouldShowError || shouldShowEmailSent}
                popperChildComponent={(shouldShowError || shouldShowEmailSent) && toolTipElement}
                child={exportBtn}
                childClassName="childClassName"
                popperClassName="popperClassName"
            />
        </div>
    );
};

export default ShipmentExportButton;
