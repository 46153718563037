import { IFormattedPrimaryDetailStep, IFormattedStep } from "./types";
import PillContainer from "../PillContainer/PillContainer";
import { ReactComponent as CurrentStepIcon } from "../../assets/icons/current-step.svg";
import { ReactComponent as NextStepIcon } from "../../assets/icons/next-step.svg";
import { useEffect, useState } from "react";
import { isFormattedPrimaryDetailStep } from "./utils";
import UnicargoTooltip from "../UnicargoTooltip/UnicargoTooltip";
import "./Pill.scss";

interface IPill {
    currentStep: IFormattedPrimaryDetailStep | IFormattedStep;
    nextStep: IFormattedPrimaryDetailStep | IFormattedStep;
    isInland: boolean;
    shouldShowTooltip?: boolean;
}

interface IPillData {
    current: {
        destination?: string;
        date?: string | null;
        description?: string;
    };
    next: {
        destination?: string;
        date?: string | null;
        description?: string;
    };
}

const initialPillData: IPillData = {
    current: { destination: "", date: "", description: "" },
    next: { destination: "", date: "", description: "" },
};

const Pill = ({ currentStep, nextStep, isInland, shouldShowTooltip = true }: IPill) => {
    const [pillData, setPillData] = useState<IPillData>(initialPillData);

    useEffect(() => {
        const temp = structuredClone(initialPillData);

        // TODO: Delete the default values after Chen provides specifications on the empty states in this component
        if (isFormattedPrimaryDetailStep(currentStep)) {
            temp.current.date = currentStep.date;
            temp.current.description = currentStep.title;
        } else {
            const descriptionAndDates = currentStep?.descriptionsAndDates.slice(-1)[0].isDone
                ? currentStep?.descriptionsAndDates.slice(-1)[0]
                : currentStep?.descriptionsAndDates[0];
            temp.current.description = descriptionAndDates?.description;
            temp.current.date = descriptionAndDates?.actualDates.slice(-1)[0];
            temp.current.destination = currentStep?.destination;
        }

        if (nextStep && isFormattedPrimaryDetailStep(nextStep)) {
            temp.next.date = nextStep.date;
            temp.next.description = nextStep.title;
        } else {
            const descriptionAndDates = nextStep?.descriptionsAndDates.find(desc => !desc.isDone) ||
                nextStep?.descriptionsAndDates[0];
            temp.next.description = descriptionAndDates?.description;
            temp.next.date =
                descriptionAndDates?.actualDates.slice(-1)[0] || descriptionAndDates?.estimatedDates.slice(-1)[0];
            temp.next.destination = nextStep?.destination;
        }

        setPillData(temp);
    }, [currentStep, nextStep]);

    const currentStepTextContent =
        (pillData.current.description ? pillData.current.description : "") +
        // We don't want to show the date if it's an Inland shipment and the current step is "InTransit"
        (pillData.current.date && !(isInland && currentStep.cardName === "inTransit")
            ? ` ${pillData.current.date}`
            : "");

    const nextStepTextContent =
        pillData.next.description +
        // We don't want to show the date if it's an Inland shipment and the next step is "InTransit"
        (pillData.next.date && !(isInland && nextStep?.cardName === "inTransit") ? ` ${pillData.next.date}` : "");

    const currentStepLength = currentStepTextContent.length;
    const nextStepLength = nextStepTextContent.length;

    const shouldCurrentStepHaveTooltip = currentStepLength > 20 && !nextStepTextContent.includes("undefined");
    const shouldNextStepHaveTooltip = nextStepLength > 20;
    const currentStepCardName = currentStep?.cardName === "inTransit" ? "In Transit" : currentStep?.cardName ?? "";
    const nextStepCardName = nextStep?.cardName === "inTransit" ? "In Transit" : nextStep?.cardName ?? "";

    const currentStepRef = (
        <div className="pill-content-container">
            <div className="pill-icon">
                <CurrentStepIcon />
            </div>
            {/* // change in transit card name to in transit */}
            <div className="pill-text-content">
                <span className="pill-text">Current Step:</span>
                <span>
                    {currentStepTextContent
                        ? `${currentStepCardName ? `${currentStepCardName} - ` : ""}${currentStepTextContent}`
                        : currentStepCardName === "In Transit"
                            ? "In Transit"
                            : currentStepCardName
                    }
                </span>
            </div>
        </div>
    );

    const nextStepRef =
        pillData.next.destination != undefined ? (
            <div className="pill-content-container">
                <div className="pill-icon">
                    <NextStepIcon />
                </div>
                <div className="pill-text-content">
                    <span className="pill-text">Next Step:</span>
                    <span> 
                        {nextStepTextContent
                            ? `${nextStepCardName ? `${nextStepCardName} - ` : ""}${nextStepTextContent}`
                            : nextStepCardName === "In Transit"
                                ? "In Transit"
                                : nextStepCardName}
                    </span>
                </div>
            </div>
        ) : (
            <></>
        );

    return (
        <PillContainer width="100%" className="pill-container">
            {shouldShowTooltip && shouldCurrentStepHaveTooltip ? (
                <UnicargoTooltip
                    popperChildComponent={<span className="pill-text">{currentStepTextContent}</span>}
                    child={currentStepRef}
                />
            ) : (
                currentStepRef
            )}

            {shouldShowTooltip && shouldNextStepHaveTooltip ? (
                <UnicargoTooltip
                    popperChildComponent={<span className="pill-text">{nextStepTextContent}</span>}
                    child={nextStepRef}
                />
            ) : (
                nextStepRef
            )}
        </PillContainer>
    );
};

export default Pill;
