import styles from "./PillContainer.module.scss";

interface IPillContainerProps {
    className?: string;
    leftSide?: JSX.Element;
    rightSide?: JSX.Element;
    height?: string;
    width?: string;
    children?: any;
}

const PillContainer = ({ className, leftSide, rightSide, height, width, children }: IPillContainerProps) => (
    <section className={`${styles.PillContainer} ${className}`} style={{ width: width, height: height, alignItems: "center" }}>
        {leftSide && <div className={`${styles.PillContainerLeftItem}`}>{leftSide}</div>}
        {rightSide && <div className={`${styles.PillContainerRightItem}`}>{rightSide}</div>}
        {children}
    </section>
);

export default PillContainer;
