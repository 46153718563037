export const primaryDetailsTranslation = {
    shipmentCreated: {
        DoorToDoor: {
            FCL: "Shipment Created",
            LCL: "Shipment Created",
            Air: "Shipment Created",
            Express: "Shipment Created",
            LTL: "Shipment Created",
            FTL: "Shipment Created",
        },
        DoorToPort: {
            FCL: "Shipment Created",
            LCL: "Shipment Created",
            Air: "Shipment Created",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToDoor: {
            FCL: "Shipment Created",
            LCL: "Shipment Created",
            Air: "Shipment Created",
            Express: "",
            LTL: "",
            FTL: "",
        },
        PortToPort: {
            FCL: "Shipment Created",
            LCL: "Shipment Created",
            Air: "Shipment Created",
            Express: "",
            LTL: "",
            FTL: "",
        },
    },
    cargoReady: {
        DoorToDoor: {
            estimatedNull: {
                FCL: "Pending Cargo Readiness",
                LCL: "Pending Cargo Readiness",
                Air: "Pending Cargo Readiness",
                Express: "Pending Cargo Readiness",
                LTL: "Pending Cargo Readiness",
                FTL: "Pending Cargo Readiness",
            },
            estimated: {
                FCL: "Planned Cargo Ready Date",
                LCL: "Planned Cargo Ready Date",
                Air: "Planned Cargo Ready Date",
                Express: "Planned Cargo Ready Date",
                LTL: "Planned Cargo Ready Date",
                FTL: "Planned Cargo Ready Date",
            },
            actual: {
                FCL: "Cargo Ready Date",
                LCL: "Cargo Ready Date",
                Air: "Cargo Ready Date",
                Express: "Cargo Ready Date",
                LTL: "Cargo Ready Date",
                FTL: "Cargo Ready Date",
            },
            actualNull: {
                FCL: "Cargo Ready",
                LCL: "Cargo Ready",
                Air: "Cargo Ready",
                Express: "Cargo Ready",
                LTL: "Cargo Ready",
                FTL: "Cargo Ready",
            },
        },
        DoorToPort: {
            estimatedNull: {
                FCL: "Pending Cargo Readiness",
                LCL: "Pending Cargo Readiness",
                Air: "Pending Cargo Readiness",
                Express: "",
                LTL: "",
                FTL: "",
            },
            estimated: {
                FCL: "Planned Cargo Ready Date",
                LCL: "Planned Cargo Ready Date",
                Air: "Planned Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actual: {
                FCL: "Cargo Ready Date",
                LCL: "Cargo Ready Date",
                Air: "Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actualNull: {
                FCL: "Cargo Ready",
                LCL: "Cargo Ready",
                Air: "Cargo Ready",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        PortToDoor: {
            estimatedNull: {
                FCL: "Pending Cargo Readiness",
                LCL: "Pending Cargo Readiness",
                Air: "Pending Cargo Readiness",
                Express: "",
                LTL: "",
                FTL: "",
            },
            estimated: {
                FCL: "Planned Cargo Ready Date",
                LCL: "Planned Cargo Ready Date",
                Air: "Planned Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actual: {
                FCL: "Cargo Ready Date",
                LCL: "Cargo Ready Date",
                Air: "Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actualNull: {
                FCL: "Cargo Ready",
                LCL: "Cargo Ready",
                Air: "Cargo Ready",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        PortToPort: {
            estimatedNull: {
                FCL: "Pending Cargo Readiness",
                LCL: "Pending Cargo Readiness",
                Air: "Pending Cargo Readiness",
                Express: "",
                LTL: "",
                FTL: "",
            },
            estimated: {
                FCL: "Planned Cargo Ready Date",
                LCL: "Planned Cargo Ready Date",
                Air: "Planned Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actual: {
                FCL: "Cargo Ready Date",
                LCL: "Cargo Ready Date",
                Air: "Cargo Ready Date",
                Express: "",
                LTL: "",
                FTL: "",
            },
            actualNull: {
                FCL: "Cargo Ready",
                LCL: "Cargo Ready",
                Air: "Cargo Ready",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
    },
    expectedBooking: {
        DoorToDoor: {
            FCL: "Pending Booking Confirmation",
            LCL: "Pending Booking Confirmation",
            Air: "Pending Airway Bill",
            Express: "Pending Airway Bill",
            LTL: "",
            FTL: "",
        },
        DoorToPort: {
            FCL: "Pending Booking Confirmation",
            LCL: "Pending Booking Confirmation",
            Air: "Pending Airway Bill",
            Express: "Pending Airway Bill",
            LTL: "",
            FTL: "",
        },
        PortToDoor: {
            FCL: "Pending Booking Confirmation",
            LCL: "Pending Booking Confirmation",
            Air: "Pending Airway Bill",
            Express: "Pending Airway Bill",
            LTL: "",
            FTL: "",
        },
        PortToPort: {
            FCL: "Pending Booking Confirmation",
            LCL: "Pending Booking Confirmation",
            Air: "Pending Airway Bill",
            Express: "Pending Airway Bill",
            LTL: "",
            FTL: "",
        },
    },
    actualBooking: {
        DoorToDoor: {
            FCL: "Booking Confirmed",
            LCL: "Booking Confirmed",
            Air: "Airway Bill Created",
            Express: "Airway Bill Created",
            LTL: "",
            FTL: "",
        },
        DoorToPort: {
            FCL: "Booking Confirmed",
            LCL: "Booking Confirmed",
            Air: "Airway Bill Created",
            Express: "Airway Bill Created",
            LTL: "",
            FTL: "",
        },
        PortToDoor: {
            FCL: "Booking Confirmed",
            LCL: "Booking Confirmed",
            Air: "Airway Bill Created",
            Express: "Airway Bill Created",
            LTL: "",
            FTL: "",
        },
        PortToPort: {
            FCL: "Booking Confirmed",
            LCL: "Booking Confirmed",
            Air: "Airway Bill Created",
            Express: "Airway Bill Created",
            LTL: "",
            FTL: "",
        },
    },
};

export const translations = {
    // ==================================== pickup ====================================
    pickup: {
        // ==================================== pickup D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "Pickup Expected On",
                LCL: "Pickup Expected On",
                Air: "Pickup Expected On",
                Express: "Pickup Expected On",
                LTL: "Pickup Expected On",
                FTL: "Pickup Expected On",
            },
            ETDNull: {
                FCL: "Scheduling Pickup",
                LCL: "Scheduling Pickup",
                Air: "Scheduling Pickup",
                Express: "Scheduling Pickup",
                LTL: "Scheduling Pickup",
                FTL: "Scheduling Pickup",
            },
            ATD: {
                FCL: "Picked Up On",
                LCL: "Picked Up On",
                Air: "Picked Up On",
                Express: "Picked Up On",
                LTL: "Picked Up On",
                FTL: "Picked Up On",
            },
            ATDNull: {
                FCL: "Picked Up",
                LCL: "Picked Up",
                Air: "Picked Up",
                Express: "Picked Up",
                LTL: "Picked Up",
                FTL: "Picked Up",
            },
            ETA: {
                FCL: "Expected Arrival To Port On",
                LCL: "Expected Arrival To CFS",
                Air: "Expected Arrival To Airport On",
                Express: "Expected Arrival To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Scheduling Arrival To Port",
                LCL: "Scheduling Arrival To CFS",
                Air: "Scheduling Arrival To Airport",
                Express: "Scheduling Arrival To Origin Hub",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived To Port On",
                LCL: "Arrived To CFS On",
                Air: "Arrived To Airport On",
                Express: "Arrived To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived To Port",
                LCL: "Arrived To CFS",
                Air: "Arrived To Airport",
                Express: "Arrived To Origin Hub",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== pickup D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "Pickup Expected On",
                LCL: "Pickup Expected On",
                Air: "Pickup Expected On",
                Express: "Pickup Expected On",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Pickup",
                LCL: "Scheduling Pickup",
                Air: "Scheduling Pickup",
                Express: "Scheduling Pickup",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Picked Up On",
                LCL: "Picked Up On",
                Air: "Picked Up On",
                Express: "Picked Up On",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Picked Up",
                LCL: "Picked Up",
                Air: "Picked Up",
                Express: "Picked Up",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Port On",
                LCL: "Expected Arrival To CFS",
                Air: "Expected Arrival To Airport On",
                Express: "Expected Arrival To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Scheduling Arrival To Port",
                LCL: "Scheduling Arrival To CFS",
                Air: "Scheduling Arrival To Airport",
                Express: "Scheduling Arrival To Origin Hub",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived To Port On",
                LCL: "Arrived To CFS On",
                Air: "Arrived To Airport On",
                Express: "Arrived To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived To Port",
                LCL: "Arrived To CFS",
                Air: "Arrived To Airport",
                Express: "Arrived To Origin Hub",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== pickup P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Port On",
                LCL: "Expected Arrival To CFS",
                Air: "Expected Arrival To Airport On",
                Express: "Expected Arrival To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Scheduling Arrival To Port",
                LCL: "Scheduling Arrival To CFS",
                Air: "Scheduling Arrival To Airport",
                Express: "Scheduling Arrival To Origin Hub",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived To Port On",
                LCL: "Arrived To CFS On",
                Air: "Arrived To Airport On",
                Express: "Arrived To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived To Port",
                LCL: "Arrived To CFS",
                Air: "Arrived To Airport",
                Express: "Arrived To Origin Hub",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== pickup P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Port On",
                LCL: "Expected Arrival To CFS",
                Air: "Expected Arrival To Airport On",
                Express: "Expected Arrival To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Scheduling Arrival To Port",
                LCL: "Scheduling Arrival To CFS",
                Air: "Scheduling Arrival To Airport",
                Express: "Scheduling Arrival To Origin Hub",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived To Port On",
                LCL: "Arrived To CFS On",
                Air: "Arrived To Airport On",
                Express: "Arrived To Origin Hub On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived To Port",
                LCL: "Arrived To CFS",
                Air: "Arrived To Airport",
                Express: "Arrived To Origin Hub",
                LTL: "",
                FTL: "",
            },
        },
    },
    // ==================================== main carriage ====================================
    mainCarriage: {
        // ==================================== main carriage D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "In Transit",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Your Booking",
                LCL: "Scheduling Your Booking",
                Air: "Scheduling Your Booking",
                Express: "In Transit",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "In Transit",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "In Transit",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "Arrival Expected On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Vessel Arrival To Port",
                LCL: "Pending Vessel Arrival To Port",
                Air: "Pending Flight Arrival To Airport",
                Express: "Pending Flight Arrival To Airport",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "Flight Arrived To Airport On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "Flight Arrived To Airport",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== main carriage D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Your Booking",
                LCL: "Scheduling Your Booking",
                Air: "Scheduling Your Booking",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "Arrival Expected On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Vessel Arrival To Port",
                LCL: "Pending Vessel Arrival To Port",
                Air: "Pending Flight Arrival To Airport",
                Express: "Pending Flight Arrival To Airport",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "Flight Arrived To Airport On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "Flight Arrived To Airport",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== main carriage P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Your Booking",
                LCL: "Scheduling Your Booking",
                Air: "Scheduling Your Booking",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "Arrival Expected On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Vessel Arrival To Port",
                LCL: "Pending Vessel Arrival To Port",
                Air: "Pending Flight Arrival To Airport",
                Express: "Pending Flight Arrival To Airport",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "Flight Arrived To Airport On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "Flight Arrived To Airport",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== main carriage P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Your Booking",
                LCL: "Scheduling Your Booking",
                Air: "Scheduling Your Booking",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "Arrival Expected On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Vessel Arrival To Port",
                LCL: "Pending Vessel Arrival To Port",
                Air: "Pending Flight Arrival To Airport",
                Express: "Pending Flight Arrival To Airport",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "Flight Arrived To Airport On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "Flight Arrived To Airport",
                LTL: "",
                FTL: "",
            },
        },
    },
    // ==================================== transshipment ====================================
    transshipment: {
        // ==================================== transshipment D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure",
                LCL: "Pending Departure",
                Air: "Pending Departure",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "Arrival Expected On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Vessel Arrival To Port",
                LCL: "Vessel Arrival To Port",
                Air: "Flight Arrival To Airport",
                Express: "Flight Arrival To Airport",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "Flight Arrived To Airport On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "Flight Arrived To Airport",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== transshipment D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure",
                LCL: "Pending Departure",
                Air: "Pending Departure",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Vessel Arrival To Port",
                LCL: "Vessel Arrival To Port",
                Air: "Flight Arrival To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== transshipment P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure",
                LCL: "Pending Departure",
                Air: "Pending Departure",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Vessel Arrival To Port",
                LCL: "Vessel Arrival To Port",
                Air: "Flight Arrival To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== transshipment P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "Expected To Depart On",
                LCL: "Expected To Depart On",
                Air: "Expected To Depart On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure",
                LCL: "Pending Departure",
                Air: "Pending Departure",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Vessel Departed On",
                LCL: "Vessel Departed On",
                Air: "Flight Departed On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Vessel Departed",
                LCL: "Vessel Departed",
                Air: "Flight Departed",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Arrival Expected On",
                LCL: "Arrival Expected On",
                Air: "Arrival Expected On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Vessel Arrival To Port",
                LCL: "Vessel Arrival To Port",
                Air: "Flight Arrival To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Vessel Arrived To Port On",
                LCL: "Vessel Arrived To Port On",
                Air: "Flight Arrived To Airport On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Vessel Arrived To Port",
                LCL: "Vessel Arrived To Port",
                Air: "Flight Arrived To Airport",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
    },
    // ==================================== on carriage ====================================
    onCarriage: {
        // ==================================== on carriage D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "Expected Departure To Destination Terminal On",
                LCL: "Expected Departure To Destination Terminal On",
                Air: "Expected Departure To Destination Terminal On",
                Express: "Expected Departure To Destination Terminal On",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure To Destination Terminal",
                LCL: "Pending Departure To Destination Terminal",
                Air: "Pending Departure To Destination Terminal",
                Express: "Pending Departure To Destination Terminal",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Departed To Destination Terminal On",
                LCL: "Departed To Destination Terminal On",
                Air: "Departed To Destination Terminal On",
                Express: "Departed To Destination Terminal On",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Departed To Destination Terminal",
                LCL: "Departed To Destination Terminal",
                Air: "Departed To Destination Terminal",
                Express: "Departed To Destination Terminal",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Destination Terminal On",
                LCL: "Expected Arrival To Destination Terminal On",
                Air: "Expected Arrival To Destination Terminal On",
                Express: "Expected Arrival To Destination Terminal On",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Arrival To Destination Terminal",
                LCL: "Pending Arrival To Destination Terminal",
                Air: "Pending Arrival To Destination Terminal",
                Express: "Pending Arrival To Destination Terminal",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived At Destination Terminal On",
                LCL: "Arrived At Destination Terminal On",
                Air: "Arrived At Destination Terminal On",
                Express: "Arrived At Destination Terminal On",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived At Destination Terminal",
                LCL: "Arrived At Destination Terminal",
                Air: "Arrived At Destination Terminal",
                Express: "Arrived At Destination Terminal",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== on carriage D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "Expected Departure To Destination Terminal On",
                LCL: "Expected Departure To Destination Terminal On",
                Air: "Expected Departure To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure To Destination Terminal",
                LCL: "Pending Departure To Destination Terminal",
                Air: "Pending Departure To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Departed To Destination Terminal On",
                LCL: "Departed To Destination Terminal On",
                Air: "Departed To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Departed To Destination Terminal",
                LCL: "Departed To Destination Terminal",
                Air: "Departed To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Destination Terminal On",
                LCL: "Expected Arrival To Destination Terminal On",
                Air: "Expected Arrival To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Arrival To Destination Terminal",
                LCL: "Pending Arrival To Destination Terminal",
                Air: "Pending Arrival To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived At Destination Terminal On",
                LCL: "Arrived At Destination Terminal On",
                Air: "Arrived At Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived At Destination Terminal",
                LCL: "Arrived At Destination Terminal",
                Air: "Arrived At Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== on carriage P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "Expected Departure To Destination Terminal On",
                LCL: "Expected Departure To Destination Terminal On",
                Air: "Expected Departure To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure To Destination Terminal",
                LCL: "Pending Departure To Destination Terminal",
                Air: "Pending Departure To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Departed To Destination Terminal On",
                LCL: "Departed To Destination Terminal On",
                Air: "Departed To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Departed To Destination Terminal",
                LCL: "Departed To Destination Terminal",
                Air: "Departed To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Destination Terminal On",
                LCL: "Expected Arrival To Destination Terminal On",
                Air: "Expected Arrival To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Arrival To Destination Terminal",
                LCL: "Pending Arrival To Destination Terminal",
                Air: "Pending Arrival To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived At Destination Terminal On",
                LCL: "Arrived At Destination Terminal On",
                Air: "Arrived At Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived At Destination Terminal",
                LCL: "Arrived At Destination Terminal",
                Air: "Arrived At Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== on carriage P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "Expected Departure To Destination Terminal On",
                LCL: "Expected Departure To Destination Terminal On",
                Air: "Expected Departure To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Pending Departure To Destination Terminal",
                LCL: "Pending Departure To Destination Terminal",
                Air: "Pending Departure To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Departed To Destination Terminal On",
                LCL: "Departed To Destination Terminal On",
                Air: "Departed To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Departed To Destination Terminal",
                LCL: "Departed To Destination Terminal",
                Air: "Departed To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Expected Arrival To Destination Terminal On",
                LCL: "Expected Arrival To Destination Terminal On",
                Air: "Expected Arrival To Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Arrival To Destination Terminal",
                LCL: "Pending Arrival To Destination Terminal",
                Air: "Pending Arrival To Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Arrived At Destination Terminal On",
                LCL: "Arrived At Destination Terminal On",
                Air: "Arrived At Destination Terminal On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Arrived At Destination Terminal",
                LCL: "Arrived At Destination Terminal",
                Air: "Arrived At Destination Terminal",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
    },
    availableForDelivery: {
        // ==================================== available for delivery D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Container Availability",
                LCL: "Pending Availability at CFS",
                Air: "Pending Shipment Availability",
                Express: "Pending Shipment Availability",
                LTL: "Pending Package Availability",
                FTL: "Pending Package Availability",
            },
            ATA: {
                FCL: "Available For Pickup On",
                LCL: "Available For Pickup On",
                Air: "Available For Pickup On",
                Express: "Available For Pickup On",
                LTL: "Available For Delivery On",
                FTL: "Available For Delivery On",
            },
            ATANull: {
                FCL: "Available For Pickup",
                LCL: "Available For Pickup",
                Air: "Available For Pickup",
                Express: "Available For Pickup",
                LTL: "Available For Delivery",
                FTL: "Available For Delivery",
            },
        },
        // ==================================== available for delivery D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== available for delivery P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== available for delivery P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETANull: {
                FCL: "Pending Container Availability",
                LCL: "Pending Availability at CFS",
                Air: "Pending Shipment Availability",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Available For Pickup On",
                LCL: "Available For Pickup On",
                Air: "Available For Pickup On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Available For Pickup",
                LCL: "Available For Pickup",
                Air: "Available For Pickup",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
    },
    // ==================================== pickup for delivery D2D ====================================
    pickupForDelivery: {
        // ==================================== pickup for delivery D2D ====================================
        DoorToDoor: {
            ETD: {
                FCL: "Pickup For Delivery Expected On",
                LCL: "Pickup For Delivery Expected On",
                Air: "Pickup For Delivery Expected On",
                Express: "Pickup For Delivery Expected On",
                LTL: "Pickup For Delivery Expected On",
                FTL: "Pickup For Delivery Expected On",
            },
            ETDNull: {
                FCL: "Scheduling Delivery Appointment",
                LCL: "Scheduling Delivery Appointment",
                Air: "Scheduling Delivery Appointment",
                Express: "Scheduling Delivery Appointment",
                LTL: "Scheduling Delivery Appointment",
                FTL: "Scheduling Delivery Appointment",
            },
            ATD: {
                FCL: "Out For Delivery On",
                LCL: "Out For Delivery On",
                Air: "Out For Delivery On",
                Express: "Out For Delivery On",
                LTL: "Out For Delivery On",
                FTL: "Out For Delivery On",
            },
            ATDNull: {
                FCL: "Out For Delivery",
                LCL: "Out For Delivery",
                Air: "Out For Delivery",
                Express: "Out For Delivery",
                LTL: "Out For Delivery",
                FTL: "Out For Delivery",
            },
            ETA: {
                FCL: "Delivery Scheduled On",
                LCL: "Delivery Scheduled On",
                Air: "Delivery Scheduled On",
                Express: "Delivery Scheduled On",
                LTL: "Delivery Scheduled On",
                FTL: "Delivery Scheduled On",
            },
            ETANull: {
                FCL: "Scheduling Delivery",
                LCL: "Scheduling Delivery",
                Air: "Scheduling Delivery",
                Express: "Scheduling Delivery",
                LTL: "Scheduling Delivery",
                FTL: "Scheduling Delivery",
            },
            ATA: {
                FCL: "Shipment Delivered On",
                LCL: "Shipment Delivered On",
                Air: "Shipment Delivered On",
                Express: "Shipment Delivered On",
                LTL: "Shipment Delivered On",
                FTL: "Shipment Delivered On",
            },
            ATANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== pickup for delivery D2P ====================================
        DoorToPort: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Delivery Scheduled On",
                LCL: "Delivery Scheduled On",
                Air: "Delivery Scheduled On",
                Express: "Delivery Scheduled On",
                LTL: "Delivery Scheduled On",
                FTL: "Delivery Scheduled On",
            },
            ETANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "Shipment Delivered",
                LCL: "Shipment Delivered",
                Air: "Shipment Delivered",
                Express: "Shipment Delivered",
                LTL: "Shipment Delivered",
                FTL: "Shipment Delivered",
            },
        },
        // ==================================== pickup for delivery P2P ====================================
        PortToPort: {
            ETD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Delivery Scheduled On",
                LCL: "Delivery Scheduled On",
                Air: "Delivery Scheduled On",
                Express: "Delivery Scheduled On",
                LTL: "Delivery Scheduled On",
                FTL: "Delivery Scheduled On",
            },
            ETANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
        // ==================================== pickup for delivery P2D ====================================
        PortToDoor: {
            ETD: {
                FCL: "Pickup For Delivery Expected On",
                LCL: "Pickup For Delivery Expected On",
                Air: "Pickup For Delivery Expected On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETDNull: {
                FCL: "Scheduling Delivery Appointment",
                LCL: "Scheduling Delivery Appointment",
                Air: "Scheduling Delivery Appointment",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATD: {
                FCL: "Out For Delivery On",
                LCL: "Out For Delivery On",
                Air: "Out For Delivery On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATDNull: {
                FCL: "Out For Delivery",
                LCL: "Out For Delivery",
                Air: "Out For Delivery",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ETA: {
                FCL: "Delivery Scheduled On",
                LCL: "Delivery Scheduled On",
                Air: "Delivery Scheduled On",
                Express: "Delivery Scheduled On",
                LTL: "Delivery Scheduled On",
                FTL: "Delivery Scheduled On",
            },
            ETANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATA: {
                FCL: "Shipment Delivered On",
                LCL: "Shipment Delivered On",
                Air: "Shipment Delivered On",
                Express: "",
                LTL: "",
                FTL: "",
            },
            ATANull: {
                FCL: "",
                LCL: "",
                Air: "",
                Express: "",
                LTL: "",
                FTL: "",
            },
        },
    },
};
