import { IPostDelivery, IShipmentDetails, IShipmentPreDelivery } from "../../models/entities/shipment/IShipment";
import { BoatIconV2, TruckIconV2, AirplaneIconV2, WarehouseIconV2 } from "../../assets/icons";
import {
    DescriptionAndDate,
    IFormatSteps,
    IFormattedPrimaryDetailStep,
    IFormattedStep,
    ScheduleType,
    ShipmentLoad,
} from "./types";
import { formatDate, getDescriptionAndDateType, getShipmentMoveType } from "./utils";
import { primaryDetailsTranslation, translations } from "./constant";
import { ETransportModes } from "../../models";
import { getShipmentCurrentStage } from "../../helpers/services/ShipmentService";
import { getCardColor } from "../cards/shipment-cards/shared/services/shipment-cards.service";
import { COLORS, UiSchemeV2 } from "../../assets/data/ui";
import makeFlatSteps from "./makeFlatSteps";

const getFormatPrimaryDetails = (
    isDone: boolean,
    dotColor: string,
    preDelivery: IShipmentPreDelivery,
    postDelivery: IPostDelivery,
    shipmentDetails: IShipmentDetails,
) => {
    const shipmentLoad = shipmentDetails.shipmentLoad as ShipmentLoad;
    const shipmentMoveType = getShipmentMoveType(shipmentDetails.moveTypeEnglishName);

    const isBookingDone = isDone || !!postDelivery.booking;
    const isCargoReadyDone = isBookingDone || !!preDelivery.approvedCargoReadyDate;
    const isShipmentCreatedDone = isCargoReadyDone || !!preDelivery.orderConfirmed;

    // Empty states for cargo ready
    let cargoReadyState: "actual" | "estimated" | "actualNull" | "estimatedNull" = preDelivery.approvedCargoReadyDate
        ? "actual"
        : "estimated";
    if (!preDelivery.plannedCargoReadyDate && !preDelivery.approvedCargoReadyDate && isDone)
        cargoReadyState = "actualNull";
    if (preDelivery.plannedCargoReadyDate && !preDelivery.approvedCargoReadyDate && isDone)
        cargoReadyState = "actualNull";
    else if (!preDelivery.plannedCargoReadyDate && !preDelivery.approvedCargoReadyDate && !isDone)
        cargoReadyState = "estimatedNull";

    const primaryDetails: IFormattedPrimaryDetailStep[] = [
        {
            name: "shipmentCreated",
            cardName: "origin",
            title: primaryDetailsTranslation.shipmentCreated[shipmentMoveType][shipmentLoad],
            date: formatDate(preDelivery.orderConfirmed)[0],
            isDone: isShipmentCreatedDone,
            dotColor: isShipmentCreatedDone ? dotColor : UiSchemeV2.colors.greys.grey200,
        },
        {
            name: "cargoReady",
            cardName: "origin",
            title: primaryDetailsTranslation.cargoReady[shipmentMoveType][cargoReadyState][shipmentLoad],
            date: formatDate(preDelivery.approvedCargoReadyDate)[0] || formatDate(preDelivery.plannedCargoReadyDate)[0],
            isDone: isCargoReadyDone,
            dotColor: isCargoReadyDone ? dotColor : UiSchemeV2.colors.greys.grey200,
        },
        {
            name: "booking",
            cardName: "origin",
            title: isBookingDone
                ? primaryDetailsTranslation.actualBooking[shipmentMoveType][shipmentLoad]
                : primaryDetailsTranslation.expectedBooking[shipmentMoveType][shipmentLoad],
            isDone: isBookingDone,
            dotColor: isBookingDone ? dotColor : UiSchemeV2.colors.greys.grey200,
            date: null,
        },
    ];

    const result = primaryDetails.filter((item) => item.title.trim() !== "");

    return result;
};

const getCurrentAndNextSteps = (formattedSteps: IFormattedStep[], primaryDetails: IFormattedPrimaryDetailStep[]) => {
    const stepsInOrder = [...formattedSteps, ...primaryDetails.reverse()];

    const currentStepIndex = stepsInOrder.findIndex((step) => step.isDone);
    const currentStep = stepsInOrder[currentStepIndex];

    const nextStep = [...stepsInOrder].reverse().find(step => {
        if(!step.isDone) return true;

        if ("descriptionsAndDates" in step) {
            return step.descriptionsAndDates.some(desc => !desc.isDone);
        }
        
        return false;
    }) || stepsInOrder[currentStepIndex - 1];

    // Inserting the current color
    const harderColors = {
        [COLORS.system.origin]: UiSchemeV2.colors.originDark,
        [COLORS.system.inTransit]: UiSchemeV2.colors.inTransitDark,
        [COLORS.system.destination]: UiSchemeV2.colors.destinationDark,
    };

    currentStep.dotColor = harderColors[currentStep.dotColor];
    primaryDetails.reverse();

    return { currentStep, nextStep };
};

const shipmentIcons = {
    Air: AirplaneIconV2,
    Inland: TruckIconV2,
    Ocean: BoatIconV2,
    Warehouse: WarehouseIconV2,
};

const formatSteps = ({ steps, shipmentDetails, preDelivery, postDelivery }: IFormatSteps) => {
    const dotColor = getCardColor(
        getShipmentCurrentStage(
            steps,
            ETransportModes[shipmentDetails.transportMode],
            postDelivery.PODRecievedDate,
            shipmentDetails.mainCarriageATA,
            shipmentDetails,
        ),
    );

    let formattedSteps: IFormattedStep[] = [];

    const shipmentLoad = shipmentDetails.shipmentLoad as ShipmentLoad;

    const shipmentMoveType = getShipmentMoveType(shipmentDetails.moveTypeEnglishName);

    const flatSteps = makeFlatSteps({ shipmentMoveType, steps, shipmentDetails });

    let isDone = false;
    let previousStepData: DescriptionAndDate[] = [];

    const landTransportMode = shipmentDetails.transportMode === "Inland";

    flatSteps.reverse().map((step, index) => {
        const previousStep = flatSteps[index + 1];

        if (!!step.ATD || !!step.ATA) isDone = true;

        let departureTime: ScheduleType = step.ATD ? "ATD" : "ETD";
        if (!step.ETD?.length && !step.ATD && isDone) departureTime = "ATDNull";
        if (step.ETD?.length && !step.ATD && isDone) departureTime = "ATDNull";
        else if (!step.ETD?.length && !step.ATD && !isDone) departureTime = "ETDNull";

        let arrivalTime: ScheduleType = step.ATA ? "ATA" : "ETA";
        if (!step.ETA?.length && !step.ATA && isDone) arrivalTime = "ATANull";
        if (step.ETA?.length && !step.ATA && isDone) arrivalTime = "ATANull";
        if (!step.ETA?.length && !step.ATA && !isDone) arrivalTime = "ETANull";

        const scheduleType = step.arrivalOrDeparture === "arrival" ? arrivalTime : departureTime;
        const actualDates = step.arrivalOrDeparture === "arrival" ? formatDate(step.ATA) : formatDate(step.ATD);
        const estimatedDates = step.arrivalOrDeparture === "arrival" ? formatDate(step.ETA) : formatDate(step.ETD);

        // Need to show only "In Transit", without date
        const landTransportModeHideDate =
            landTransportMode &&
            (step.name === "departureOriginPortInOrigin" || step.name === "departureOriginPortInTransit");

        // Don't want to show this because its going to be inserted in another step
        const shouldInsertStepToPrevious =
            previousStep?.destination?.toLowerCase() === step.destination?.toLowerCase() &&
            previousStep?.cardName === step.cardName &&
            !(step.name === "departureOriginPortInOrigin" && isDone);

        // In land transport mode there are no 2 steps in one location
        if (shouldInsertStepToPrevious && !landTransportMode) {
            previousStepData.push({
                isDone,
                actualDates: actualDates,
                estimatedDates: estimatedDates,
                description: translations[step.translationObject][shipmentMoveType][scheduleType][shipmentLoad],
                type: getDescriptionAndDateType(actualDates, estimatedDates, scheduleType, landTransportModeHideDate),
            });

            // Must - do not delete!
            previousStepData.reverse();
        } else {
            formattedSteps.push({
                isDone,
                name: step.name,
                cardName: `${
                    step.name === "departureOriginPortInOrigin" && isDone && !landTransportMode
                        ? "inTransit"
                        : step.cardName
                }`,
                destination:
                    step.destination?.includes("null") || step.destination?.includes("undefined")
                        ? (step.translationObject === "pickup" 
                            ? "Supplier Site" 
                            : step.translationObject === "pickupForDelivery" 
                                ? "Consignee Site" 
                                : "Cargo Facility")
                        : (landTransportMode && step.translationObject === "mainCarriage") ? "In Transit"
                         : step.destination?.toLowerCase(),
                icon: step.icon || shipmentIcons[shipmentDetails.transportMode],
                dotColor: isDone ? dotColor : UiSchemeV2.colors.greys.grey200,
                descriptionsAndDates: [
                    {
                        isDone: landTransportMode && step.translationObject === "mainCarriage" && step.name !== "departureOriginPortInTransit" &&
                        step.name !== "departureOriginPortInOrigin" ? false : isDone,
                        actualDates: actualDates,
                        estimatedDates: estimatedDates,
                        description: translations[step.translationObject][shipmentMoveType][scheduleType][shipmentLoad],
                        type: getDescriptionAndDateType(
                            actualDates,
                            estimatedDates,
                            scheduleType,
                            landTransportModeHideDate,
                        ),
                    },
                    ...(previousStepData ? [...previousStepData] : []),
                ],
            });
            previousStepData = [];
        }
    });

    const primaryDetails: IFormattedPrimaryDetailStep[] = getFormatPrimaryDetails(
        isDone,
        dotColor,
        preDelivery,
        postDelivery,
        shipmentDetails,
    );

    // This is the steps we need to show in the land card
    // If departure exist in origin array (meaning there is mainCarriage.ETD) && departure exist in in transit array (meaning there is mainCarriage.ATD) show only the one in the in transit
    if (landTransportMode) {
        formattedSteps = formattedSteps.filter(
            (item) =>
                item.name === "pickup" ||
                item.name === "departureOriginPortInTransit" ||
                (item.name === "departureOriginPortInOrigin" &&
                    !formattedSteps.some((step) => step.name === "departureOriginPortInTransit")) ||
                item.name === "delivery",
        );
    }

    // In case we are in Land mode, if we have PickupATD, it means we are in transit
    if (landTransportMode ) {
        const pickupStep = formattedSteps.find((step) => step.name === "pickup");

        // if we have PickupATD or MainCarriageATD, it means In Transit is the current step
        if(pickupStep?.descriptionsAndDates[0].actualDates[0]) {
            // In this case we will change the isDone of In Transit step to true
            formattedSteps = formattedSteps.map((step) => {
                if(step.destination === "In Transit") {
                    step.isDone = true;
                    step.descriptionsAndDates[0].isDone = true;
                    step.dotColor = step.isDone ? dotColor : UiSchemeV2.colors.greys.grey200;
                }
                return step;
            });
        }
    }
    const { currentStep, nextStep } = getCurrentAndNextSteps(formattedSteps, primaryDetails);

    formattedSteps.reverse();

    // Special treatment for mainCarriage.ATD/mainCarriage.ETD
    // If one of the next steps is completed, we should display mainCarriage.ATDNull in the "In Transit" card
    // Otherwise, we need to display it in the "Origin" card
    // This is handled here because we have to use the "isDone" flag
    const originIndex = formattedSteps.findIndex((step) => step.name === "departureOriginPortInOrigin");
    const transitIndex = formattedSteps.findIndex((step) => step.name === "departureOriginPortInTransit");
    const originIndexInPrevStep = formattedSteps.findIndex(
        (step) => step.name === "arrivalToOriginPort" && step.descriptionsAndDates.length > 1,
    );

    if ((originIndex !== -1 || originIndexInPrevStep !== -1) && transitIndex !== -1) {
        const originStep = formattedSteps[originIndex];
        const originStepInPrevStep = formattedSteps[originIndexInPrevStep];
        const transitStep = formattedSteps[transitIndex];

        if (!originStep?.isDone || !originStepInPrevStep?.isDone) {
            formattedSteps.splice(transitIndex, 1);
        } else if (!transitStep?.isDone) {
            formattedSteps.splice(originIndex, 1);
        }
    }

    const originSteps = formattedSteps.filter((step) => step.cardName === "origin");

    const isBookingFinished = primaryDetails.find((step) => step.name === "booking")?.isDone;

    let inTransitSteps = isBookingFinished ? formattedSteps.filter((step) => step.cardName === "inTransit") : [];
    let destinationSteps = isBookingFinished ? formattedSteps.filter((step) => step.cardName === "destination") : [];

    if (landTransportMode) {
        destinationSteps = formattedSteps.filter((step) => step.cardName === "destination");
        inTransitSteps = formattedSteps.filter((step) => step.cardName === "inTransit");
    }

    return {
        flatSteps,
        dotColor,
        currentStep,
        nextStep,
        primaryDetails,
        originSteps,
        inTransitSteps,
        destinationSteps,
    };
};

export default formatSteps;
